import { useState, useContext } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";

import { AppBar, Box, Button, IconButton, Toolbar, Typography, Menu, MenuItem, Divider } from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import VpnKeyIcon from "@mui/icons-material/VpnKey";
import InstagramIcon from "@mui/icons-material/Instagram";

import AuthContext from "../../store/auth-context";
import ThemeModeContext from "../../store/theme-context";
import Title from "./Title";
import api from "../../api/api";

const TopBar = props => {
  const navigate = useNavigate();
  const authCtx = useContext(AuthContext);

  const { isLoggedIn, userName } = authCtx;

  const [hiddenIfHomePage, setHiddenIfHomePage] = useState("hidden");

  const location = useLocation();

  if (location.pathname === "/" && hiddenIfHomePage !== "hidden") {
    setHiddenIfHomePage("hidden");
  } else if (location.pathname !== "/" && hiddenIfHomePage === "hidden") {
    setHiddenIfHomePage("");
  }

  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenu = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const userTeamsQuery = useQuery({ queryKey: ["userTeams", authCtx.token], queryFn: getTeams, enabled: !!authCtx.token, refetchOnWindowFocus: false });
  const activeUserTeams = userTeamsQuery.data?.activeTeams;

  return (
    <AppBar position="relative" color="transparent" sx={{ boxShadow: "none" }}>
      <Toolbar disableGutters>
        <Box>
          <Title />
        </Box>
        <Box sx={{ ml: "auto", mr: { md: 2 } }}>
          {isLoggedIn ? (
            <>
              <IconButton
                sx={{ display: "flex", alignItems: "end", borderRadius: 3, py: 0.5 }}
                onClick={handleMenu}
                aria-controls="menu-user"
                aria-haspopup="true"
              >
                <Typography variant="subtitle1" sx={{ pb: 0.3, wordBreak: "break-word", textAlign: "end" }}>
                  {userName}
                </Typography>
                <PersonIcon fontSize="large" />
              </IconButton>
              <Menu open={Boolean(anchorEl)} anchorEl={anchorEl} id="menu-user" onClose={handleClose}>
                <MenuItem
                  onClick={() => {
                    handleClose();
                    navigate(`/profile/${authCtx.userId}`);
                  }}
                >
                  Profil
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    handleClose();
                    authCtx.logout();
                  }}
                >
                  Log ud
                </MenuItem>
                {/* ACTIVE USERTEAMS */}
                {activeUserTeams?.length
                  ? [
                      <Divider key={1213213} />,
                      <Box key={2342242} sx={{ px: "16px", fontSize: 14, textAlign: "center" }}>
                        AKTIVE HOLD
                      </Box>,
                      ...activeUserTeams.map(team => (
                        <MenuItem
                          key={team._id}
                          onClick={() => {
                            handleClose();
                            navigate(`/team/${team._id}`);
                          }}
                        >
                          <Box>
                            <Box>{team.name}</Box>
                            <Box sx={{ fontSize: 11, color: "text.secondary" }}>{team.raceId.fullName}</Box>
                          </Box>
                        </MenuItem>
                      )),
                    ]
                  : null}
              </Menu>
            </>
          ) : (
            <>
              <Button component={Link} variant="contained" to="/login" sx={{ display: { xs: "none", sm: "inline-flex" }, mr: 2 }}>
                Log ind
              </Button>
              <IconButton component={Link} to="/login" sx={{ display: { xs: "inline-flex", sm: "none" }, mr: 0.5 }}>
                <VpnKeyIcon />
              </IconButton>
            </>
          )}
        </Box>
      </Toolbar>
    </AppBar>
  );

  async function getTeams() {
    const response = await api.get("/teams/getUserTeams/", {
      headers: {
        Authorization: "Bearer " + authCtx.token,
      },
    });
    return response.data;
  }
};

export default TopBar;
