import React, { useState, createContext } from "react";
import { darkThemeOptions, lightThemeOptions } from "../themeOptions";

interface ContextProps {
  darkMode: boolean;
  themeOptions: Record<string, any>;
  toggleDarkMode: () => void;
  setLightMode: () => void;
  setDarkMode: () => void;
}

const ThemeModeContext = createContext<ContextProps>({
  darkMode: true,
  themeOptions: {},
  toggleDarkMode: () => {},
  setLightMode: () => {},
  setDarkMode: () => {},
});

interface Props {
  children: React.ReactNode;
}

export const ThemeModeContextProvider: React.FC<Props> = props => {
  const [darkMode, setDarkMode] = useState<boolean>(true);

  const toggleDarkModeHandler = () => {
    setDarkMode(prev => {
      localStorage.setItem("lightModePref", prev.toString());
      return !prev;
    });
  };

  const setLightModeHandler = () => {
    localStorage.setItem("lightModePref", "true");
    setDarkMode(false);
  };

  const setDarkModeHandler = () => {
    localStorage.setItem("lightModePref", "false");
    setDarkMode(true);
  };

  const contextValue: ContextProps = {
    darkMode: darkMode,
    themeOptions: darkMode ? darkThemeOptions : lightThemeOptions,
    toggleDarkMode: toggleDarkModeHandler,
    setLightMode: setLightModeHandler,
    setDarkMode: setDarkModeHandler,
  };

  return <ThemeModeContext.Provider value={contextValue}>{props.children}</ThemeModeContext.Provider>;
};

export default ThemeModeContext;
