import React, { useState } from "react";
import moment from "moment";

import AuthCtxTypes from "../interfaces/AuthContextTypes";
import api from "src/api/api";

const AuthContext = React.createContext<AuthCtxTypes>({
  token: "",
  userId: "",
  userName: "",
  isLoggedIn: false,
  autosaveOn: false,
  login: (token, userId, userName, autosaveOn) => {},
  logout: () => {},
  toggleAutosave: autosaveEnabled => {},
});

type Props = {
  children: React.ReactNode;
};
export const AuthContextProvider: React.FC<Props> = ({ children }) => {
  const [token, setToken] = useState<string | null>(localStorage.getItem("token"));
  const [userId, setUserId] = useState<string | null>(localStorage.getItem("userId"));
  const [userName, setUserName] = useState<string | null>(localStorage.getItem("userName"));
  const [autosaveOn, setAutosaveOn] = useState<boolean | null>(localStorage.getItem("autosaveOn") === "true");

  const userIsLoggedIn = !!token;

  const loginHandler = (token: string, userId: string, userName: string, autosaveOn: boolean) => {
    localStorage.setItem("token", token);
    localStorage.setItem("userId", userId);
    localStorage.setItem("userName", userName);
    localStorage.setItem("autosaveOn", autosaveOn?.toString());
    setToken(token);
    setUserId(userId);
    setUserName(userName);
    setAutosaveOn(autosaveOn);
  };

  const logoutHandler = () => {
    setToken(null);
    setUserId(null);
    setUserName(null);
    setAutosaveOn(null);
    localStorage.removeItem("token");
    localStorage.removeItem("userId");
    localStorage.removeItem("userName");
    localStorage.removeItem("autosaveOn");
  };

  const toggleAutosaveHandler = (autosaveEnabled: boolean) => {
    localStorage.setItem("autosaveOn", autosaveEnabled?.toString());
    setAutosaveOn(autosaveEnabled);
    try {
      api.put(
        "user/toggle-autosave/",
        {
          autosaveEnabled,
        },
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );
    } catch (err) {
      console.log(err);
    }
  };

  const contextValue = {
    token: token,
    userId: userId,
    userName: userName,
    isLoggedIn: userIsLoggedIn,
    autosaveOn: autosaveOn,
    login: loginHandler,
    logout: logoutHandler,
    toggleAutosave: toggleAutosaveHandler,
  };

  return <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>;
};

export default AuthContext;
