import React from "react";
import { Box } from "@mui/material";
import LoadingSpinner from "./LoadingSpinner";

export default function SuspenseFallback() {
  return (
    <Box sx={{ display: "grid", placeItems: "center" }}>
      <LoadingSpinner />
    </Box>
  );
}
