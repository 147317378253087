const breakpoints = {
  values: {
    xs: 0,
    sm: 600,
    md: 900,
    tablet: 960,
    lg: 1200,
    xl: 1536,
  },
};

const score = {
  lightExtra: "hsl(120, 100%, 45%)",
  extra: "hsl(120, 100%, 26%)",
  extraNegative: "rgb(134, 47, 47)",
};

const primary = {
  main: "#f8931d",
  light: "#fab361",
  dark: "#c67617",
  veryLight: "#fcd4a5",
  veryDark: "#633b0c",
  contrastText: "#3e3e3e",
  contrastTextSecondary: "#656565",
};

const secondary = {
  main: "hsl(172, 100%, 20%)",
  light: "hsl(172, 100%, 25%)",
  dark: "hsl(172, 100%, 15%)",
  contrastText: "#e0e0e0",
  contrastTextDisabled: "rgba(224,224,224,0.45)",
  darkContrastText: "black",
};

const contrast = { dark: "#3e3e3e", light: "#e0e0e0" };

const rider = {
  default: "#3e3e3e",
  notInRace: "rgba(62,62,62,0.45)",
};

export const darkThemeOptions: Record<string, any> = {
  palette: {
    mode: "dark",
    primary: primary,
    secondary: secondary,
    background: {
      default: "hsl(212, 32%, 8%)",
      paper2: "hsl(212, 32%, 11%)",
      paper: "hsl(196, 100%, 12%)",
      list: "hsl(172, 80%, 20%)",
      listSecondary: "hsl(172, 80%, 18%)",
    },
    text: {
      primary: "#e0e0e0",
      secondary: "rgba(197,197,197,0.8)",
      hint: "rgba(224,224,224,0.45)",
      disabled: "rgba(224,224,224,0.45)",
      list: "white",
    },
    rider: rider,
    action: { active: "#CFD8DC" },
    link: {
      active: "rgba(0, 0, 0, 0.20)",
    },
    succes: {
      main: "#218E0B",
    },
    error: { main: "#D90808" },
    info: {
      main: "#87AEb4",
    },
    card: {
      main: "#515151",
    },
    score: score,
    contrast: contrast,
  },
  breakpoints: breakpoints,
};

export const lightThemeOptions: Record<string, any> = {
  palette: {
    mode: "light",
    primary: primary,
    secondary: secondary,
    text: {
      primary: "#252525",
      secondary: "rgba(62,62,62,0.7)",
      hint: "rgba(62,62,62,0.60)",
      disabled: "rgba(62,62,62,0.45)",
      list: "black",
    },
    rider: rider,
    background: {
      default: "#e0e0e0",
      paper: "#e9e9e9",
      // list: "hsl(188, 23%, 69%)",
      list: "hsl(180, 13%, 89%)",
    },
    link: {
      active: "rgba(31, 31, 31, 0.10)",
    },
    succes: {
      main: "#218E0B",
    },
    error: { main: "#D90808" },
    info: {
      main: "#87AEb4",
    },
    card: {
      main: "#cacaca",
    },
    score: score,
    contrast: contrast,
  },
  breakpoints: breakpoints,
};
