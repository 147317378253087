import { lazy, Suspense, useEffect } from "react";
import { BrowserRouter, Routes, Route, useLocation, useNavigationType, createRoutesFromChildren, matchRoutes } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import * as Sentry from "@sentry/react";
import "./index.css";
import { createRoot } from "react-dom/client";

import App from "./App";
import SuspenseFallback from "./components/UI/SuspenseFallback";

const FrontPage = lazy(() => import("./pages/FrontPage"));
const Race = lazy(() => import("./pages/RacePage"));
const Login = lazy(() => import("./pages/LoginPage"));
const Signup = lazy(() => import("./pages/SignupPage"));
const CreateNewTeam = lazy(() => import("./pages/CreateNewTeamPage"));
const TeamPage = lazy(() => import("./pages/TeamPage"));
const Groupettos = lazy(() => import("./pages/GroupettosPage"));
const Groupetto = lazy(() => import("./pages/GroupettoPage"));
const QuizPage = lazy(() => import("./pages/QuizPage"));
const QuizzesPage = lazy(() => import("./pages/QuizzesPage"));
const AdminPage = lazy(() => import("./pages/AdminPage"));
const EmailsPage = lazy(() => import("./pages/admin/EmailsPage"));
const AdminQuizzesPage = lazy(() => import("./pages/admin/AdminQuizzesPage"));
const AdminRacePage = lazy(() => import("./pages/admin/AdminRacePage"));
const AdminQuizPage = lazy(() => import("./pages/admin/AdminQuizPage"));
const RaceAdminPage = lazy(() => import("./pages/admin/RaceAdminPage"));
const RaceAdminRacePage = lazy(() => import("./pages/admin/RaceAdminRacePage"));
const Profile = lazy(() => import("./pages/ProfilePage"));
const RequestResetPassword = lazy(() => import("./pages/RequestResetPasswordPage"));
const ChooseNewPassword = lazy(() => import("./pages/ChooseNewPasswordPage"));
const EmailOptOut = lazy(() => import("./pages/EmailOptOutPage"));
const PublicCoinOverview = lazy(() => import("./pages/PublicCoinOverviewPage"));
const JoinGroupetto = lazy(() => import("./pages/JoinGroupettoPage"));
const BirthdaysPage = lazy(() => import("./pages/BirthdaysPage"));
const ContactPage = lazy(() => import("./pages/ContactPage"));
const ErrorTest = lazy(() => import("./pages/ErrorTestPage"));
const ReactQueryDevtools = lazy(() => import("@tanstack/react-query-devtools").then((module) => ({ default: module.ReactQueryDevtools })));

import { AuthContextProvider } from "./store/auth-context";
import { ThemeModeContextProvider } from "./store/theme-context";

import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import { Button } from "@mui/material";
import { Link } from "react-router-dom";

Sentry.init({
  dsn: "https://d3712156bc85296a9ce22d7db1614c20@o4505880253825024.ingest.sentry.io/4505880257232896",
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ["localhost", /^https:\/\/api\.cykeljusteringen\.dk/, /^https:\/\/apiv2\.cykeljusteringen\.dk/],
      routingInstrumentation: Sentry.reactRouterV6Instrumentation(useEffect, useLocation, useNavigationType, createRoutesFromChildren, matchRoutes),
    }),
    new Sentry.Replay(),
  ],
  // Performance Monitoring
  tracesSampleRate: process.env.NODE_ENV === "production" ? 0.2 : 0, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: process.env.NODE_ENV === "production" ? 0.1 : 0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const queryClient = new QueryClient();

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

const rootElement = document.getElementById("root");
if (!rootElement) {
  throw new Error("Could not find root element");
}
export const root = createRoot(rootElement);

root.render(
  <QueryClientProvider client={queryClient}>
    <AuthContextProvider>
      <ThemeModeContextProvider>
        <BrowserRouter>
          <SentryRoutes>
            <Route path="/" element={<App />}>
              <Route
                path=""
                element={
                  <Suspense fallback={<SuspenseFallback />}>
                    <FrontPage />
                  </Suspense>
                }
              />
              <Route
                path="race/:raceId"
                element={
                  <Suspense fallback={<SuspenseFallback />}>
                    <Race />
                  </Suspense>
                }
              />
              <Route
                path="login"
                element={
                  <Suspense fallback={<SuspenseFallback />}>
                    <Login />
                  </Suspense>
                }
              />
              <Route
                path="signup"
                element={
                  <Suspense fallback={<SuspenseFallback />}>
                    <Signup />
                  </Suspense>
                }
              />
              <Route
                path="create-team/:raceId/:fullName"
                element={
                  <Suspense fallback={<SuspenseFallback />}>
                    <CreateNewTeam />
                  </Suspense>
                }
              />
              <Route
                path="team/:teamId"
                element={
                  <Suspense fallback={<SuspenseFallback />}>
                    <TeamPage />
                  </Suspense>
                }
              />
              <Route
                path="groupettos/:raceId"
                element={
                  <Suspense fallback={<SuspenseFallback />}>
                    <Groupettos />
                  </Suspense>
                }
              />
              <Route
                path="groupetto/:groupettoId"
                element={
                  <Suspense fallback={<SuspenseFallback />}>
                    <Groupetto />
                  </Suspense>
                }
              />
              <Route
                path="quizzes"
                element={
                  <Suspense fallback={<SuspenseFallback />}>
                    <QuizzesPage />
                  </Suspense>
                }
              />
              <Route
                path="quiz/:quizId"
                element={
                  <Suspense fallback={<SuspenseFallback />}>
                    <QuizPage />
                  </Suspense>
                }
              />

              <Route
                path="join-groupetto/:inviteToken"
                element={
                  <Suspense fallback={<SuspenseFallback />}>
                    <JoinGroupetto />
                  </Suspense>
                }
              />
              <Route
                path="admin"
                element={
                  <Suspense fallback={<SuspenseFallback />}>
                    <AdminPage />
                  </Suspense>
                }
              />
              <Route
                path="admin/emails"
                element={
                  <Suspense fallback={<SuspenseFallback />}>
                    <EmailsPage />
                  </Suspense>
                }
              />
              <Route
                path="admin/quizzes"
                element={
                  <Suspense fallback={<SuspenseFallback />}>
                    <AdminQuizzesPage />
                  </Suspense>
                }
              />
              <Route
                path="admin/quiz/:quizId"
                element={
                  <Suspense fallback={<SuspenseFallback />}>
                    <AdminQuizPage />
                  </Suspense>
                }
              />
              <Route
                path="admin/race/:raceId"
                element={
                  <Suspense fallback={<SuspenseFallback />}>
                    <AdminRacePage />
                  </Suspense>
                }
              />
              <Route
                path="admin/race-admin"
                element={
                  <Suspense fallback={<SuspenseFallback />}>
                    <RaceAdminPage />
                  </Suspense>
                }
              />
              <Route
                path="admin/race-admin/:raceId"
                element={
                  <Suspense fallback={<SuspenseFallback />}>
                    <RaceAdminRacePage />
                  </Suspense>
                }
              />
              <Route
                path="request-reset-password"
                element={
                  <Suspense fallback={<SuspenseFallback />}>
                    <RequestResetPassword />
                  </Suspense>
                }
              />
              <Route
                path="reset-password/:resetToken"
                element={
                  <Suspense fallback={<SuspenseFallback />}>
                    <ChooseNewPassword />
                  </Suspense>
                }
              />
              <Route
                path="profile/:userId"
                element={
                  <Suspense fallback={<SuspenseFallback />}>
                    <Profile />
                  </Suspense>
                }
              />
              <Route
                path="email-opt-out/:emailType/:optOutToken"
                element={
                  <Suspense fallback={<SuspenseFallback />}>
                    <EmailOptOut />
                  </Suspense>
                }
              />
              <Route
                path="public-coin-overview/:raceId"
                element={
                  <Suspense fallback={<SuspenseFallback />}>
                    <PublicCoinOverview />
                  </Suspense>
                }
              />
              <Route
                path="birthdays"
                element={
                  <Suspense fallback={<SuspenseFallback />}>
                    <BirthdaysPage />
                  </Suspense>
                }
              />

              <Route
                path="/contact"
                element={
                  <Suspense fallback={<SuspenseFallback />}>
                    <ContactPage />
                  </Suspense>
                }
              />
              <Route
                path="/test-error"
                element={
                  <Suspense fallback={<SuspenseFallback />}>
                    <ErrorTest />
                  </Suspense>
                }
              />
              <Route
                path="*"
                element={
                  <main style={{ padding: 10 }}>
                    <h4 style={{ fontSize: 25 }}>Siden blev ikke fundet..</h4>
                    <Button to="/" variant="outlined" component={Link}>
                      Gå til forsiden
                    </Button>
                  </main>
                }
              />
            </Route>
          </SentryRoutes>
        </BrowserRouter>
        <Suspense>
          <ReactQueryDevtools />
        </Suspense>
      </ThemeModeContextProvider>
    </AuthContextProvider>
  </QueryClientProvider>,
);
