import axios, { AxiosInstance } from "axios";

interface ImportMeta {
  readonly env: {
    readonly MODE: string;
  };
}

let api: AxiosInstance;

if (process.env.NODE_ENV === "production") {
  api = axios.create({ baseURL: "https://apiv2.cykeljusteringen.dk" });
} else {
  api = axios.create({ baseURL: "http://localhost:5000" });
  // api = axios.create({ baseURL: "https://apiv2.cykeljusteringen.dk" });
  console.log("Running in development mode. Connecting to localhost:5000");
}

export default api;
