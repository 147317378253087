import { useEffect } from "react";
import { useLocation } from "react-router-dom";

import analytics from "../utility/analytics";

export default function useGoogleAnalytics() {
  const location = useLocation();

  useEffect(() => {
    // if (import.meta.env.MODE !== "development") {
      analytics.init();
    // }
  }, []);

  useEffect(() => {
    const currentPath = location.pathname + location.search;

    analytics.sendPageview(currentPath);
  }, [location]);
}
