import ReactGA from "react-ga4";

const TRACKING_ID = "G-1259NSVB48";

function init() {
  // Enable debug mode on the local development environment
  // console.log("init");
  const isDev = import.meta.env.MODE === "development";
  ReactGA.initialize(TRACKING_ID, { testMode: isDev, gaOptions: { cookieFlags: "SameSite=None; Secure" } });
}

function sendEvent(payload: { category: string; action: string; label?: string; value?: number; nonInteraction?: boolean }) {
  // console.log("event, payload:" + payload);
  // if (import.meta.env.MODE !== "development") {
  ReactGA.event(payload);
  // }
}

function sendPageview(path: string) {
  // console.log("page_view, path:" + path);
  // if (import.meta.env.MODE !== "development") {
  ReactGA.set({ page: path });
  ReactGA.send({ hitType: "pageview", page: path });
  // }
}

export default {
  init,
  sendEvent,
  sendPageview,
};
