import React from "react";
import "./LoadingSpinner.css";
import { Box, SxProps } from "@mui/material";

const LoadingSpinner = ({ color, sx }: { color?: string, sx?: SxProps }) => {
  const getColor = () => {
    if (color === "black") {
      return "black";
    }
    return "";
  };

  return (
    <Box className="loading-spinner-container" sx={sx}>
      <div className="lds-ring">
        <div className={getColor()}></div>
        <div className={getColor()}></div>
        <div className={getColor()}></div>
        <div className={getColor()}></div>
      </div>
    </Box>
  );
};

export default LoadingSpinner;
