import { useEffect, useContext } from "react";
import AuthContext from "../store/auth-context";
import jwt_decode from "jwt-decode";
import moment from "moment";
import { useNavigate } from "react-router-dom";

export default function useCheckForStoredToken() {
  const navigate = useNavigate();
  const authCtx = useContext(AuthContext);

  useEffect(() => {
    const localStorageToken = localStorage.getItem("token");
    if (authCtx.token) {
      const decodedToken = jwt_decode(authCtx.token);
      if (decodedToken) {
        const expiresAt = moment(new Date(decodedToken.exp * 1000));
        const now = moment();
        if (now.isAfter(expiresAt)) {
          authCtx.logout();
          navigate("/", { replace: true });
        }
      }
    } else {
      if (!!localStorageToken) {
        const userId = localStorage.getItem("userId");
        const userName = localStorage.getItem("userName");
        const autosaveOn = localStorage.getItem("autosaveOn") === "true" ? true : false;
        authCtx.login(localStorageToken, userId, userName, autosaveOn);
      }
    }
  });
}
